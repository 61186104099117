import axios from 'axios';

export const parseQueryString = function (query) {
  if (!query) {
    query = window.location.search.substring(1);
  }
  var vars = query.split('&');
  var queryString = {};
  for (let i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    var key = pair[0];
    var value = pair[1];
    queryString[key] = value;
  }
  return queryString;
};

export const findContextPath = _.memoize(function () {
  return application.utils.baseUrl();
});

export function findAuthParam() {
  const authParams = ['appToken', 'basicAuthQp', 'key', 'appKey'];
  const queryString = parseQueryString();

  for (const authParam of authParams) {
    const value = queryString[authParam];
    if (queryString[authParam]) {
      return {
        key: authParam,
        value,
      };
    }
  }

  return null;
}

export function buildUrlWithAuth(url = '') {
  const authParam = findAuthParam();
  if (authParam) {
    if (url.indexOf(`${authParam.key}=`) === -1) {
      const val = `&${authParam.key}=${authParam.value}`;
      if (url.indexOf('?') === -1) {
        url += '?';
      }
      url += val;
    }
  }

  const contextPath = findContextPath();
  if (contextPath && !url.includes(contextPath)) {
    url = contextPath + url;
  }

  return url;
}

export const configureJQueryAjaxPrefilter = _.memoize(() => {
  const authParams = ['appToken', 'basicAuthQp', 'key', 'appKey'];
  j.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    const queryString = parseQueryString();
    let match = false;
    for (const authParam of authParams) {
      if (queryString[authParam] && options.url.indexOf(`${authParam}=`) === -1) {
        const val = `&${authParam}=${queryString[authParam]}`;
        if (options.url.indexOf('?') === -1) {
          options.url += '?';
        }
        options.url += val;
        match = true;
        break;
      }
    }

    if (!match && window.__AUTHORIZATION && window.__AUTHORIZATION.length > 0) {
      jqXHR.setRequestHeader('Authorization', window.__AUTHORIZATION);
    }

    if (options.url.indexOf('/spr/') === 0 || options.url.indexOf('/api/') === 0) {
      const contextPath = findContextPath();
      if (contextPath && options.url.indexOf(contextPath) !== 0) {
        options.url = contextPath + options.url;
      }
    }
  });

  j(document).on('ajaxError', async function (event, jqxhr, settings, thrownError) {
    try {
      const status = jqxhr?.status ?? 0;
      await reloadPageOnUnauthorized(status);
    } catch (e) {
      console.error('Error on configureJQueryAjaxPrefilter.ajaxError handler', e);
    }
  });
});

class AxiosClass {
  constructor(axiosInstance) {
    axiosInstance.interceptors.request.use((config) => {
      const randomString = Math.random().toString(36).substring(2);
      const timestamp = Date.now();

      const newParams = Object.assign({}, config.params || {}, { _: `${timestamp}${randomString}` });

      const authQp = findAuthParam();
      if (authQp) {
        newParams[authQp.key] = authQp.value;
      } else if (window.__AUTHORIZATION?.length > 0) {
        config.headers = Object.assign({}, config.headers || {}, { Authorization: window.__AUTHORIZATION });
      }

      config.params = newParams;
      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        try {
          const status = error.response?.status ?? 0;
          await reloadPageOnUnauthorized(status);
        } catch (e) {
          console.error('Error on AxiosClass response error handler', e);
        }
        return Promise.reject(error);
      }
    );

    Object.assign(this, axiosInstance);
  }

  cancelableRequest = async (url, params, config = {}) => {
    return await this.post(url, params, config).catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        return { cancelRequest: true, error: thrown.message };
      } else {
        return { cancelRequest: false, error: thrown };
      }
    });
  };

  generateCancelToken = () => {
    return axios.CancelToken.source();
  };

  getData = async (url = '', config = {}) => {
    const { data } = await this.get(url, config);
    return data;
  };

  postData = async (url = '', requestData, config = {}) => {
    const { data } = await this.post(url, requestData, config);
    return data;
  };
}

export const Axios = new AxiosClass(
  axios.create({
    baseURL: findContextPath(),
    paramsSerializer(params) {
      if (!params) return '';
      return j.param(params);
    },
  })
);

export const isFolder = (path) => {
  return !path.includes('.');
};

export const executeJsfExp = (exp) => {
  return new Promise((resolve, reject) => {
    const $exp = j('.jsf-bridge-exp');
    const $btn = j('.jsf-bridge-btn');
    if ($exp.length > 0 && $btn.length > 0) {
      const name = `Api.executeExp.listener-${_.random(0, 9999)}`;
      application.ice.addNewListener(name, _.noop, () => {
        resolve(true);
        application.ice.removeListener(name);
      });
      $exp[0].value = exp;
      $btn[0].click();
    } else {
      resolve(true);
    }
  });
};

let reloadPageOnForbiddenIsRunning = false;

async function reloadPageOnUnauthorized(status = 0) {
  if (reloadPageOnForbiddenIsRunning || window?.location?.pathname?.includes('/bng/login')) {
    return;
  }
  reloadPageOnForbiddenIsRunning = true;
  try {
    if (status === 401 || status === 403) {
      const response = await Axios.get(`/spr/users/me`, {
        validateStatus() {
          return true;
        },
      });
      const status = response.status ?? 0;
      if (status === 401) {
        const url = buildUrl('/spr/bng/login', { cause: 'session-expired' });
        window.location.replace(url);
      }
    }
  } finally {
    reloadPageOnForbiddenIsRunning = false;
  }
}

export function buildUrl(url = '', params = {}) {
  if (!url.startsWith('/')) {
    url = `/${url}`;
  }

  return Axios.getUri({
    url,
    params,
  });
}
